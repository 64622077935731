import React from "react"
import { graphql } from "gatsby"
import Layout from "../../layout"
import { GatsbyImage } from "gatsby-plugin-image"

const BlogPost = ({ data }) => {
  const {
    title,
    featuredImage,
    date,
    content,
    categories,
    author,
  } = data.wpPost

  const seo = {
    title: title,
    meta: [
      {
        property: "og:image",
        content: featuredImage.node.mediaItemUrl,
      },
    ],
  }

  const category = categories.nodes.map(node => (
    <span className="mr-4 no-underline hover:opacity-100">{node.name}</span>
  ))

  return (
    <Layout seo={seo}>
      <div className="md:max-w-[70%] mx-auto pt-14 single-post">
        <GatsbyImage
          image={featuredImage.node.localFile.childImageSharp.gatsbyImageData}
        />

        <article className="md:max-w-[80%] max-w-[85%] mx-auto mt-12">
          <span className="text-[18px]">{date}</span>
          <h2 className="mt-4 text-[#292A2C]">{title}</h2>
          <div
            className="mt-4"
            dangerouslySetInnerHTML={{ __html: content }}
          ></div>
          <p className="py-8 opacity-50">
            Published by {author.node.name} in {category}{" "}
          </p>
        </article>
      </div>
    </Layout>
  )
}

export default BlogPost

export const pageQuery = graphql`
  query($slug: String) {
    wpPost(slug: { eq: $slug }) {
      title
      slug
      date(formatString: "LL")
      excerpt
      content
      categories {
        nodes {
          name
          slug
        }
      }
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          mediaItemUrl
          localFile {
            childImageSharp {
              gatsbyImageData(
                layout: FULL_WIDTH
                placeholder: BLURRED
                quality: 100
              )
            }
          }
        }
      }
    }
  }
`
